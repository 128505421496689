import React, {
	useState,
  useEffect
} from 'react';

import { Section, SectionTitle, SectionShadowText } from "../../components/Section"
import ProductCardList from "../../components/ProductCardList"
import NoResult from "../../components/NoResult"
import {CardInfo, CardInfoBody, FilterGrid, FilterGridItem, FilterLabel, FilterGridOr, OrSep} from "../../components/FilterComponent"

const ProductSection = (props) => {
	const {sectionShadow, sectionTitle, data, buildingType, buildingApplication, openQuoteModal } = props;
	const [currentCategory, setCategory] = useState('All');
	const [categoryBuildingTypes, setCategoryBuildingTypes] = useState(buildingType);
	const [currentBuildingType, setCurrentBuildingType] = useState('All');
	const [currentProductApp, setProductApp] = useState('All');
	const [currentPriceRange, setPriceRange] = useState('All');
	const [currentRoofStyle, setRoofStyle] = useState('All');

  useEffect(() => {
    var selectedBuildingTypes = []
    if (currentCategory === 'All') {
      selectedBuildingTypes = buildingType
    } else {
      buildingType.forEach(function(item) {
        if (item.node.mainCategory.name === currentCategory) {
          selectedBuildingTypes.push(item)
        }
      })
    }
    setCategoryBuildingTypes(selectedBuildingTypes)
  }, [currentCategory, setCategory]);

  const onSetProductApp = (e) => {
    setProductApp(e.target.value);
    setCurrentBuildingType("All");
  }

  const onSetCurrentBuildingType = (e) => {
    setCurrentBuildingType(e.target.value);
    setProductApp("All")
  }

	return(
		<Section pt="90px" pb="0" xpt="60px" xpb="0" bgColor="#F7F7F7">
			<div className="container">
				<SectionShadowText>{sectionShadow}</SectionShadowText>      
				<SectionTitle>{sectionTitle}</SectionTitle>      
			</div>
			<CardInfo className='filter-info'>   
        <CardInfoBody>                    
          <div className="container">                        
            <FilterGrid className="filter-grid">   
              <FilterGridItem>
                <FilterLabel className="filter-label">Filters By : </FilterLabel>
              </FilterGridItem>
              <FilterGridItem>
                  <div className="control-label-static">Building Type</div>
                  <div className="form-group-bordered">
                    <select
                      id="building_type"
                      className="form-control"
                      onChange={(e) => onSetCurrentBuildingType(e)}
                      value={currentBuildingType}
                      aria-label="building type"
                    >
                      <option value="All">All Building Type</option>
                      {
                        categoryBuildingTypes.map(item => (
                          <option value={item} key={item}>
                            {item}
                          </option>
                        ))
                      }
                    </select>
                  </div>
              </FilterGridItem>
              <FilterGridOr>
                <OrSep>OR</OrSep>
              </FilterGridOr>
              <FilterGridItem>
                  <div className="control-label-static">Building Application</div>    
                  <div className="form-group-bordered">
                    <select
                      id="building_app"
                      className="form-control"
                      onChange={(e) => onSetProductApp(e)}
                      value={currentProductApp}
                      aria-label="building application"
                    >
                      <option value="All">All Building Application</option>
                      {
                        buildingApplication.map(item => (
                          <option value={item.node.name} key={item.node.name}>
                            {item.node.name}
                          </option>
                        ))
                      }
                    </select>
                  </div>                           
              </FilterGridItem>
              <FilterGridItem>
                  <div className="control-label-static">Price Range</div>    
                  <div className="form-group-bordered">
                      <select
                        id="price_range"
                        className="form-control"
                        onChange={(e) => setPriceRange(e.target.value)}
                        aria-label="price range"
                      >
                          <option value="All">All Price Range</option>
                          <option value="0-2000">$0-$2000</option>
                          <option value="2000-5000">$2000-$5000</option>
                          <option value="5000-20000">$5000-$20000</option>
                          <option value="20000">$20000 Above</option>
                      </select>
                  </div>                           
              </FilterGridItem>
            </FilterGrid>
          </div>   
        </CardInfoBody>
    	</CardInfo>		
			<div className="container">
        <div style={{display:'none'}} ><NoResult /></div>  
			  <ProductCardList
          data={data.edges}
          category={currentCategory}
          buildingType={currentBuildingType}
          buildingApp={currentProductApp}
          price={currentPriceRange}
          roofStyle={currentRoofStyle}
          openQuoteModal={openQuoteModal}
        />
			</div>
		</Section>
	)
}

export default ProductSection
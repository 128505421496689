import React from "react"
import axios from "axios"
import { navigate } from "gatsby"
import CloseIcon from "../Icons/CloseIcon"
import FormButton from "../Button/FormButton"
import StateSelect from '../StateSelect'
import RightLineArrowIcon from '../Icons/RightLineArrowIcon'
import {FormControlLabel } from "../Section"
import {
  Modal,
  ModalTitle,
  CloseButton,
  ModalDialog,
  ModalContent,
  ModalBody,
  ModalBackdrop,
} from "../Modal"

class QuotePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      source: '',
      page: '',
      form: '',
      full_name: "",
      email: "",
      phone_no: "",
      state: null,
      zipcode: "",
      comment: "",
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeSelect = this.handleChangeSelect.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleChange(event) {
    const { id, value } = event.target
    this.setState({
      [id]: value,
    })
  }
  handleChangeSelect(state) {
    this.setState({state})
  }
  handleSubmit(event) {
    event.preventDefault()
    const { location } = this.props
    const data = {
      source: location.origin,
      page: location.href,
      form: event.target.id,
      first_name: this.state.full_name,
      email: this.state.email,
      phone_no: this.state.phone_no,
      state: this.state.state.value,
      zipcode: this.state.zipcode,
      comment: this.state.comment,
      api_token: process.env.GATSBY_FORM_CRM_TOKEN,
    }
    if (typeof window !== `undefined`) {
			document.getElementById("quotePopupBtn").setAttribute("disabled", "");
		}
    axios({
      method: "post",
      url: "https://crm.senseicrm.com/api/create-lead-wordpress",
      data: data,
      headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN },
    })
      .then(res => {
        if (typeof dataLayer !== 'undefined') {
          window.dataLayer.push({
            'event': 'form_submission',
            'formName': 'QuotePopup',
            'enhanced_conversion_data': {
              'email': this.state.email,
              'phone_number': this.state.phone_no
            }
          });
        }
        document.body.classList.remove("popup-open")
        this.setState({
          source: "",
          page: "",
          form: "",
          full_name: "",
          email: "",
          phone_no: "",
          state: "",
          zipcode: "",
          comment: "",
        })
        navigate("/thank-you")
        if (typeof window !== `undefined`) {
          document.body.classList.remove("modal-open")
        }
      })
      .catch(error => {
        alert(error)
      })
  }
  render() {
    const { isVisible } = this.props
    return (
      <>
      <Modal
        className={"modal fade " + (isVisible ? "show" : "")}
        style={{ display: isVisible ? "block" : "none" }}
      >
        <ModalDialog className="modal-dialog modal-xl modal-dialog-centered">
          <ModalContent className="modal-content">
            <ModalBody className="modal-body">
              <CloseButton className="close" onClick={this.props.onClose}>
                <CloseIcon />
              </CloseButton>
              <ModalTitle className="modal-title">
                Get Started Today With Your Free Quote
              </ModalTitle>
              <form
                id="quoteform"
                className="quoteform grid"
                onSubmit={this.handleSubmit}
              >
                <div className="grid-12">
                  <div className="form-group-bordered">
                    <input
                      type="text"
                      id="full_name"
                      className="form-control"
                      pattern="[A-Za-z\s]+$"
                      onChange={this.handleChange}
                      required
                    />
                    <FormControlLabel
                      htmlFor="full_name"
                      className={
                        this.state.full_name !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Name
                    </FormControlLabel>
                  </div>
                </div>
                <div className="grid-12">
                  <div className="form-group-bordered">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    />
                    <FormControlLabel
                      htmlFor="email"
                      className={
                        this.state.email !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Email Address
                    </FormControlLabel>
                  </div>
                </div>
                <div className="grid-12">
                  <div className="form-group-bordered">
                    <input
                      type="tel"
                      id="phone_no"
                      className="form-control"
                      onChange={this.handleChange}
                      pattern="[0-9]{10}"
                      required
                    />
                    <FormControlLabel
                      htmlFor="phone_no"
                      className={
                        this.state.phone_no !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Phone Number
                    </FormControlLabel>
                  </div>
                </div>
                <div className="grid-md-6">
                  <div className="form-group-bordered">
                    <input
                      id="zipcode"
                      type="text"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                      pattern="[0-9]+$"
                    />
                    <FormControlLabel
                      htmlFor="zipcode"
                      className={
                        this.state.zipcode !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Zip Code
                    </FormControlLabel>
                  </div>
                </div>
                <div className="grid-md-6">
                  <div className="form-group-bordered form-group-select">
                    <StateSelect id="state" value={this.state.state}  onChange={this.handleChangeSelect} />
                  </div>
                </div>
                <div className="grid-12">
                  <div className="form-group-bordered">
                    <textarea
                      id="comment"
                      className="form-control"
                      onChange={this.handleChange}
                      required
                    />
                    <FormControlLabel
                      htmlFor="comment"
                      className={
                        this.state.comment !== ""
                          ? "control-label active"
                          : "control-label"
                      }
                    >
                      Type Your Message Here…
                    </FormControlLabel>
                  </div>
                </div>
                <div className="grid-12">
                  <div className="form-action">
                    <FormButton id="quotePopupBtn" text="Submit" icon={<RightLineArrowIcon />} />
                  </div>
                </div>
              </form>
            </ModalBody>
          </ModalContent>
        </ModalDialog>
      </Modal>
      {isVisible && (
        <ModalBackdrop className={"fade " + (isVisible ? "show" : "")} />
      )}
      </>
    )
  }
}

export default QuotePopup

import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductSection from "../sections/Product/ProductSection"
import { Section } from "../components/Section"
import QuotePopup from "../components/QuotePopup"

const _ = require("lodash")

function ProductsPage({ data, location }) {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }

  const productsData = data.allContentfulProduct
  let allSubCategories = []
  data.allContentfulProduct.edges.forEach(function (item) {
    item.node.productSubCategories.forEach(function (node) {
      allSubCategories.push(node.name)
    })
  })
  const allProductApplications = data.allContentfulProductApplication.edges
  const allRoofStyles = data.allContentfulRoofStyle.edges

  return (
    <Layout location={location}>
      <Seo
        title="Explore Our Diverse Range of Steel Building Products"
        description="Check top-quality building solutions built to your needs at Coast to Coast Carports. Browse our range of products and protect your vehicles today."
      />
      <Section pt="120px" pb="90px" xpt="54px" xpb="60px" bgColor="#F2F4F9">
        <h1 style={{ textAlign: "center", margin: "10px 0px" }}>
          Coast to Coast Carports All Products
        </h1>
        <ProductSection
          sectionShadow="Product Gallery"
          sectionTitle="Product Gallery"
          data={productsData}
          buildingType={_.uniq(allSubCategories)}
          buildingApplication={allProductApplications}
          allRoofStyles={allRoofStyles}
          openQuoteModal={() => setQuoteVisible(true)}
        />
      </Section>
      <QuotePopup
        states={data.allContentfulState.edges}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
        location={location}
      />
    </Layout>
  )
}

export default ProductsPage

export const pageQuery = graphql`
  query ProductPageQuery {
    allContentfulProduct {
      edges {
        node {
          metaTitle
          metaDescription
          productSku
          productName
          url
          productDescription {
            childMarkdownRemark {
              html
            }
          }
          featuredProduct
          productCategory {
            name
            url
          }
          productSubCategories {
            name
            url
          }
          productRoofStyle {
            name
          }
          productApplications {
            name
          }
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
          applicableForFinancing
          applicableForFreeDelivery
          applicableForRto
          otherDetails {
            childMarkdownRemark {
              html
            }
          }
          installationStates {
            name
            url
          }
        }
      }
    }
    allContentfulSubCategory {
      edges {
        node {
          name
          mainCategory {
            name
          }
        }
      }
    }
    allContentfulProductApplication {
      edges {
        node {
          name
        }
      }
    }
    allContentfulRoofStyle {
      edges {
        node {
          name
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
